import React from 'react';
import PropTypes from 'prop-types';
import { HTMLContent } from './Content'

const Testimonial = ({html, citation, contentComponent, isStandalone, isReverse}) => {
    return (
        <blockquote className={[
            'testimonial', 
            isStandalone ? 'testimonial--standalone' : '',
            isReverse ? 'testimonial--rev' : ''
        ].join(' ')}>
            <HTMLContent content={html}/>
            
            <footer>
                &mdash; <cite>{citation}</cite>
            </footer>
        </blockquote>
    )
}

Testimonial.propTypes = {
    
};

export default Testimonial;
import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import Slider from "react-slick";
import Testimonial from './Testimonial';

const Testimonials = ({ testimonials, ...other }) => {
  var settings = {
    dots: false,
    speed: 500,
    adaptiveHeight: true,
    fade: true
  };

  return (
    <Slider className="testimonial-list" {...settings}>
      { testimonials.map( item => (
        <div className="testimonial-list__item" key={v4()}>
            <Testimonial html={item.html} citation={item.frontmatter.citation} isStandalone={true} {...other}/>
        </div>
      ))}
    </Slider>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
}

export default Testimonials
